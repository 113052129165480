<template>
    <div class="container">
        <div class="row">
            <div class="col-xs-12 text-center">
                <h1 class="text-red uppercase mb-6">No posee autorización para acceder a esta ruta.</h1>
                
                <Btn
                    to='/home'
                    label="Sácame de Aqui"
                    iconPos='center'
                    icon='fas fa-home'
                />
            </div>
        </div>
    </div>
</template>

<script>
import Btn from '../../components/forms/Btn.vue'

export default({
    components(){
        Btn
    }
})
</script>
